<template>
  <div>
    <div @click="childclick" id="map" style="width:700px;height:300px;"></div>

  </div>
</template>
 
<script>
export default {
  data() {
    return {
      longitss:{},
      dizhi:{},

      
      list:{}
    };
  },
  props:["weizhi"],
  created(){},

  methods: {
    init() {
      let address = "";
      let dizhiress = {};
      let that = this;
      var center = new qq.maps.LatLng(that.weizhi.latitude,that.weizhi.longitude );
      var map = new qq.maps.Map(document.getElementById('map'), {
        center: center,
        zoom: that.weizhi.zoom,
        disableDefaultUI: true
      });
      var marker = new qq.maps.Marker({
        position: center,
        map: map
      });
      var infoWin = new qq.maps.InfoWindow({
        map: map
      });
      var geocoder = new qq.maps.Geocoder({
        complete(res) {
          dizhiress = res.detail
          address = res.detail.nearPois[0].name;
        }
      });
      qq.maps.event.addListener(map, "click", function(event) {
        this.longitude = event.latLng.getLat();
        this.latitude = event.latLng.getLng();

        let lat = new qq.maps.LatLng(this.longitude, this.latitude);
        geocoder.getAddress(lat);
        setTimeout(() => {
          that.dizhi = dizhiress
          infoWin.open();
          infoWin.setContent(
            '<div style="text-align:center;white-space:nowrap;">' +
              address +
              "</div>"
          );
          infoWin.setPosition(event.latLng);
        }, 100);
      });
    },

   async childclick(){
         setTimeout(() => {
               this.$emit('jingwei',this.dizhi)
               console.log(this.dizhi);
        }, 200);
    }
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped>
</style>